<template>
  <div class="registration-status">
    <v-container>
      <div class="main-container">
        <v-card
          rounded="lg"
          class="pa-9"
          :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
        >
          <h2>{{ 'registration.status.title' | text }}</h2>
          <v-alert
            dismissible
            type="error"
            class="mt-4"
            v-model="isShowAlert"
          >{{ alertMsg }}</v-alert>
          <v-data-table
            :headers="headers"
            :items="registrationList"
            :options.sync="options"
            :server-items-length="totalList"
            :loading="loading"
            :items-per-page="10"
            :footer-props="{
              itemsPerPageOptions: [10],
              itemsPerPageText: '',
              showCurrentPage: true
            }"
            class="elevation-2 mt-4 registration-status__table"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                small
                color='primary'
                @click="openDetail(item)"
              >
                LIHAT DETAIL
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
// Libs
import { get } from 'dot-prop'
// Constants
import {
  REGISTRATION_DETAIL
} from '@/constants/pages'
import {
  API_FORM_REGISTRATION_STATUS
} from '@/constants/apis'
import { text } from '@/apps/web/constants/text'
// Utils
import request from '@/utils/request'


  export default {
    name: 'RegistrationStatus',
    head: {
      link: [
        { rel: 'canonical', href: 'https://admisi.sttaa.ac.id/status-pendaftaran', id: 'canonical' },
      ]
    },
    data () {
      return {
        headers: [
          {
            text: 'Program Studi',
            align: 'start',
            value: 'program_study',
            class: 'black--text subtitle-2'
          },
          { text: 'Periode', value: 'period', class: 'black--text subtitle-2' },
          { text: 'Status', value: 'status', class: 'black--text subtitle-2' },
          { text: 'Detail', value: 'actions', sortable: false, class: 'black--text subtitle-2' }
        ],
        registrationList: [],
        totalList: 0,
        loading: true,
        options: {},
        isShowAlert: false,
        alertMsg: '',
      }
    },
    watch: {
      options: {
        handler () {
          this.getDataFromApi()
        },
        deep: true,
      },
    },
    methods: {
      getDataFromApi () {
        this.loading = true
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        const params = {
          limit: itemsPerPage,
          page
        }
        request(API_FORM_REGISTRATION_STATUS, params).then(res => {
          if (res.success) {
            let list = res.list
            if (sortBy.length === 1 && sortDesc.length === 1) {
              list = list.sort((a, b) => {
                const sortA = a[sortBy[0]]
                const sortB = b[sortBy[0]]

                if (sortDesc[0]) {
                  if (sortA < sortB) return 1
                  if (sortA > sortB) return -1
                  return 0
                } else {
                  if (sortA < sortB) return -1
                  if (sortA > sortB) return 1
                  return 0
                }
              })
            }
            list.map(item => {
              item.prodi = item.program_study
              item.program_study = text[`program.studi.${item.program_study}.title`]
              item.status = item.status_name
            })
            this.registrationList = list
            this.totalList = get(res.pagination, 'total_rows', 0)
          } else {
            this.alertMsg = get(res, 'resultInfo.resultMsg', 'Error System')
            this.isShowAlert = true
          }
          this.loading = false
        })
      },
      openDetail (item) {
        this.$router.push({
          name: REGISTRATION_DETAIL,
          query: {
            prodi: item.prodi,
            formId: item.form_id,
          }
        }).catch(() => {})
      }
    }
  }
</script>

<style lang="scss" scoped>
.registration-status {
  &__table {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    ::v-deep .v-data-table__wrapper {
      display: contents;
    }
  }
}
</style>